.breadcrumbs{
  margin-top: 0;
  margin-bottom: 5px;
  padding: 15px 0;
  ol{
    background: none;
    list-style: none;
    .container;
    li{
      display: inline-block;
      letter-spacing: 1px;
      .font-size(1.2);
      margin: 0;
      vertical-align: middle;
      &:first-child:before{
        font-family: 'fontAwesome';
        content:'\f015';
        display: inline-block;
        color: @body-color;
        vertical-align: middle;
      }
      &:first-child a{
        padding-left: 0;
        display: inline-block;
      }
      &:empty{
        display: none;
      }
      >span{
        display: inline-block;
        padding-left: 1em;
        padding-top: 5px\0;
        vertical-align: middle;
        .font-size(1.1);
      }
      a {
        text-decoration: none;
        display: block;
        position: relative;
        .transition(background @transition-medium ease-in-out);
        >span{
          vertical-align: middle;
        }
        &:after {
          z-index: 9;
          .arrow-right(1.5em, @white);
        }
        &:before {
          z-index: 19;
          .transition(border-color @transition-medium ease-in-out);
          .arrow-right(1.25em, darken(@light, 16%));
        }
        &:hover,
        &:focus{
        }
      }
      a,
      a:visited{
        background: none;
        line-height: 1.8;
        padding-left:1em;
        padding-right: .5em;
        padding-top: 5px\0;
        color: @body-color;
        .font-size(1.1);
      }
      a:hover,
      a:hover span{
        color: @body-color;
      }
      a:before,
      a:after{
        border:none;
        display: none;
      }
      a:after{
        display: inline-block;
        font-family: 'fontAwesome';
        content:'\f105';
        margin-top: 0;
        line-height: 0;
        right: 0em;
        font-size: 18px;
        color: @body-color;
      }
      a:hover,
      a:focus{
        text-decoration: underline;
      }
    }
  }
}
