/*
 * Definitions
 */

// Select
@select-margin: 0.4em 0;
@select-padding: 0 2rem 0 1rem;
@select-height: 3rem;
@select-font-family: @input-font-family;
@select-font-weight: @input-font-weight;
@select-font-size: 1.2rem;
@select-color: @input-color;
@select-line-height: auto;
@select-background: @input-background;
@select-border: @input-border;
@select-border-radius: @border-radius;

// Select Arrow
@select-arrow-size: 0.6rem;
@select-arrow-background: #707070;

// Select Hover
@select-hover-color: @input-hover-color;
@select-hover-background: @input-hover-background;
@select-hover-border: @input-hover-border;

// Select Focus
@select-focus-color: @input-focus-color;
@select-focus-background: @input-focus-background;
@select-focus-border: @input-focus-border;

// Select Inverse
@select-inverse-color: @input-inverse-color;
@select-inverse-border: @input-inverse-border;
@select-inverse-background: @input-inverse-background;
@select-inverse-arrow-background: @white;

// Select Inverse Hover
@select-inverse-hover-color: @input-inverse-hover-color;
@select-inverse-hover-background: @input-inverse-hover-background;
@select-inverse-hover-border: @input-inverse-hover-border;

// Select Inverse Focus
@select-inverse-focus-color: @input-inverse-focus-color;
@select-inverse-focus-background: @input-inverse-focus-background;
@select-inverse-focus-border: @input-inverse-focus-border;


/*
 * Select
 */

select {
	display: block;
	width: 100%;
}

.select {
	position: relative;
	display: inline-block;
	margin: @select-margin;
	
	// Select
	select {
		margin: 0;
		padding: @select-padding;
		height: @select-height;
		line-height: @select-line-height;
		font-family: @select-font-family;
		font-weight: @select-font-weight;
		.font-size(@select-font-size);
		color: @select-color;
		cursor: pointer;
		outline: 0;
		background: @select-background;
		border: @select-border;
		border-radius: @select-border-radius;
		.transition(all @transition-fast ease-in-out);
		.appearance(none);
		
		// Undo the Firefox inner focus ring
		&::-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}
		
		// Hide the arrow in IE10 and up
		&::-ms-expand {
			display: none;
		}

		&:hover {
			.input-style(@select-hover-color, @select-hover-background, @select-hover-border);
		}

		&:focus {
			.input-style(@select-focus-color, @select-focus-background, @select-focus-border);
		}
	}

	// Dropdown Arrow
	&:after {
		position: absolute;
		top: calc( 50% - 1px );
		right: 10px;
		width: @select-arrow-size;
		height: @select-arrow-size;
		content: '';
		pointer-events: none;
		border-right: 1px solid @select-arrow-background;
		border-bottom: 1px solid @select-arrow-background;
		transform: rotate( 45deg ) translateY( -50% );
	}
}


/*
 * Select Inverse
 */

.select-inverse {

	select {
		color: @select-inverse-color;
		border: @select-inverse-border;
		background: @select-inverse-background;

		&:hover {
			.input-style(@select-inverse-hover-color, @select-inverse-hover-background, @select-inverse-hover-border);
		}

		&:focus {
			.input-style(@select-inverse-focus-color, @select-inverse-focus-background, @select-inverse-focus-border);
		}
	}
	
	&:after {
		border-top: @select-arrow-size solid @select-inverse-arrow-background;
	}
}

// IE9 hide the arrow
@media @ie9-10 {

	.select {
	
		select {
			padding: 0.5em;
		}
		
		&:before,
		&:after {
			display: none;
		}
	}
}

// Firefox hack to hide the arrow
@-moz-document url-prefix() {
	
	// // Firefox hack to hide the arrow (FF < 30)
	// .select select {
	// 	text-indent: 0.01px;
	// 	text-overflow: '';
	// 	padding-right: 1rem;
	// }
	
	// // Firefox hack to hide the arrow (FF => 30)
	// .select:before {
	// 	z-index: 2;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	content: '';
	// 	pointer-events: none;
	// 	width: 1em;
	// 	border: @select-border;
	// 	border-width: 1px 1px 1px 0px;
	// 	background: @select-background;
	// 	.border-radiuses (0, @select-border-radius, 0, @select-border-radius);
	// }
	
	// .select.large:before {
	// 	width: 1.5em;
	// }
	
	// .select.select-inverse:before {
	// 	border: @select-inverse-border;
	// 	background: @select-inverse-background;
	// }
}