#gmenu.is-default{
	.nav-inline__item a{
		position: relative;
		@media @xsmall {
			padding: 1em 1em 1em 1.6em;
			font-weight: @bold-font-weight;
		}
	}
	.nav-inline__item a:after{
		content: '';
		display:block;
		position: absolute;
		transition: width @transition-medium ease-in-out;
		@media @small-up {
			bottom: -2px; left: 50%;
			transform: translateX( -50% );
			border-bottom: 2px solid #070707;
			width: 0;
			margin: 0 auto;
		}
		@media @xsmall {
			content: "\f105";
			font-family: fontAwesome;
			width: 1.6em;
			top: calc( 50% - 0.8em ); left: 0;
			font-weight: @medium-font-weight;
		}
	}
	.nav-inline__item a:hover:after,
	.nav-inline__item a:focus:after,
	.nav-inline__item a.current:after{
		@media @small-up {
			width: 50%;
		}
	}
}
