//一覧・フォームページ共通
.formbox{
  textarea{max-width: 100%;}
}
.element_mailform{
  .contents > .formflow{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 45px;
    padding: 0 1em;
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 25px;
      padding: 0.4em;
      width: 27%;
      max-width: 215px;
      height: 55px;
      border: 2px solid @grey;
      position: relative;
      line-height:1.5;
      font-size: 1.4rem;
      border-radius: 2px;
      white-space: nowrap;
      @media @xsmall {
        flex-direction: column;
        margin: 0 12px;
        font-size: 1.2rem;
        height: auto;
      }
      &.flow1{
        margin-left: 0;
      }
      &.flow3{
        margin-right: 0;
      }
      &.flow1:before,
      &.flow2:before,
      &.flow3:before{
        display: inline-block;
        font-family: 'Roboto';
        font-size: 1.6rem;
        line-height: 1.5;
        vertical-align: middle;
        letter-spacing: 0.14em;
        margin-right: 0.4em;
        font-weight: @bold-font-weight;
        @media @xsmall {
          margin-right: 0;
          font-size: 1.4rem;
        }
      }
      &.flow1:before{
        content: 'STEP01';
      }
      &.flow2:before{
        content: 'STEP02';
      }
      &.flow3:before{
        content: 'STEP03';
      }
      &.flow1:after,
      &.flow2:after{
        line-height: 2;
        position: absolute;
        left: ~"calc( 100% + 2px )";
        display: inline-block;
        width: 50px;
        font-family: 'hybstemplate';
        content: "\e60b";
        color:@body-color;
        text-align: center;
        font-size: 1.7rem;
        @media @xsmall {
          width: 24px;
          font-size: 1.1rem;
        }
      }
      &.current{
        background: @grey;
        color:@white;
      }
    }
  }
}

//フォーム一覧ページ
.mailform__message,
.entry_formbaner{
  @media @xsmall {
    padding-left: 20px;
    padding-right: 20px;
  }
}
//フォーム一覧ページバナー部
.entry_formbanner{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -10px;
  @media @xsmall {
    margin: 0;
  }
  .element_blog_banner{
    width: 33.333%;
    padding: 0 10px;
    margin-bottom: 20px;
    @media @xsmall {
      width: 100%;
    }
    a{
      &:extend(#side > .block .element_form_banner a);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.5;
      padding: 1em .2em;
      height: 100%;
      &:hover {
        opacity: .8;
        -ms-filter: alpha(opacity=80);
        filter: alpha(opacity=80);
      }
    }
  }
}

//フォーム詳細
.element_mailform .hs_entrytitle{
  @media @xsmall {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.element_mailform .button-wrap{
  padding: 0 20px;
  margin-top: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  @media @xsmall {
    margin-top: 3rem;
  }
  button[type="submit"]{
    display: inline-block;
    height: 7.5rem;
    font-size: 2rem;
    width: 100%;
    max-width: 400px;
    background-color: @orange;
    color: @white;
    border-width: 0;
    border-radius: 2px;
    &:hover,&:focus{
      border-width: 0;
      background-color: shade( @orange, 15% );
    }
  }
}
.formlist{
  margin-bottom: 5rem;
  @media @xsmall {
    padding: 0 20px;
    margin-bottom: 3rem;
  }
  &__row{
    display: flex;
    align-items: flex-start;
    @media @xsmall {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
.formitem{
  margin-bottom: 1.5em;
  &:last-child{
    margin-bottom: 0;
  }
  hr{
    width: 100%;
  }
  &__label{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 1em;
    span{
      font-weight: @bold-font-weight;
      color: tint( @body-color, 10% );
    }
    @media @small-up {
      width: 25%;
      flex-shrink: 0;
    }
  }
  &__require{
    margin: 0 0.8em 0 0;
    span{
      display: block;
      border-radius: 2px;
      font-size: 1.1rem;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      padding: 0.1em 0.8em;
      white-space: nowrap;
    }
    span.is-required{
      background-color: #d24123;
      color: @white;
    }
    span.is-optional{
      background-color: tint( @black, 91.6% );
      color: tint( @body-color, 15% );
    }
  }
  &__body{
    flex-grow: 1;
    input,
    textarea,
    select[multiple]{
      margin: 1rem 0;
      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    .addressZip{
      position: relative;
      >label{
        position: absolute;
        margin: 0;
        top: calc( 50% - 0.35em ); left: 0.6em;
        color: #707070;
      }
    }
    .textZip{
      display: inline-block;
      padding-left: 1.7em;
      width: auto;
      max-width: 100%;
    }
    input[name="GetAdrs"]{
      margin-top: 0;
    }
  }
  &__notice{
    display: block;
  }
  .captionTxt{
    margin: 0;
    font-size: 0.8em;
  }
  input + &__notice,
  textarea + &__notice{
    margin-top: -0.3em;
  }
  &__check,
  &__radio{
    display: inline-block;
    margin-right: 1em;
    input[type="radio"],
    input[type="checkbox"]{
      margin-right: 0.2em;
      font-size: 1.6rem;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

#ExplainSSL{
  background: @white;
  margin-bottom: 50px;
  height: 3.1em;
  overflow: hidden;
  font-size:1.2rem;
  color:lighten(@dark-grey,15%) !important;
  line-height: 1.8;
  position: relative;
  transition: all @transition-fast ease-in-out;
  margin-top:10px;
  padding:10px 25px 10px 10px;
  border: #777 solid 1px;
  color:#444;
  cursor:pointer;
  &:after{
    content:'▼';
    display: block;
    position: absolute;
    line-height: 1px;
    .font-size(1.5);
    right:10px;
    top:20px;
  }
  &.open{
    height: 20em;
    &:after{
      content:'▲';
    }
  }
  table{
    border-width: 0;
  }
  td {
    border-width: 0;
    color: lighten(@dark-grey,15%);
    padding: 0;
    font-size: 1.2rem;
  }
}
.ExplainSSL__wrapper{
  @media @xsmall {
    padding-left: 20px;
    padding-right: 20px;
  }
}