#main > .element.privacy{
	@media @xsmall {
		padding-left: 20px;
		padding-right: 20px;
	}
	b{
		.font-size(2);
		display: inline-block;
		border-top:1px solid darken(@light-grey, 21.5%);
		width: 100%;
		padding: .8em 0;
	}
}