//*****************************************
//* 共通部(メインカラム・サイドカラム)
//*****************************************/
#main > .block,
#side > .block {
  //*****************************************
  //* 共通黄色ブロック
  //*****************************************/
  //カートを見る
  .element_side_cart {
    >.title {
      width: 100%;
      display: table;
      border-top: 1px solid darken(@light-grey, 21.5%);
      h4 {
        display: table-cell;
        .font-size(1.8);
        height: 57px;
        height: 5.7rem;
        vertical-align: middle;
      }
      &:hover {
        .transition(background @transition-fast ease-in-out);
        background: @light;
      }
    }
    .cart_detail {
      margin: 0;
      th {
        background: @light-grey;
        text-align: center;
        .font-size(1);
        padding: .3em;
        vertical-align: middle;
        letter-spacing: .2em;
      }
      .basketItem {
        border-top: 1px solid @light-grey;
        &:first-child {
          border-top: none;
        }
        td {
          .font-size(1.2);
          &:last-child {
            width: 1%;
            .font-size(1.4);
            font-weight: 400;
            font-family: 'Roboto';
          }
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
          &.thum_img {
            display: none;
            +br {
              display: none;
            }
          }
        }
      }
    }
    .cart_more {
      display: none;
    }
    .cart_total {
      border-top: 1px solid @light-grey;
      border-width: 1px 0 0;
      margin-top: 0;
      .shipping, .cod, .tax {
        th,td{
          border-width: 0;
          background: none;
        }
        th {
          padding: 0 10px;
          text-align: left;
        }
        td {
          padding: .1em 10px;
          font-weight: 400;
          font-family: 'Roboto';
        }
        border-top:1px solid @light-grey;
      }
      .total {
        th, td {
          background: @light-grey;
          padding: .1em 10px;
        }
        th {
          text-align: left;
        }
        td strong {
          //合計金額
          .font-size(2);
          font-weight: 600;
          font-family: 'Roboto';
        }
      }
      td[colspan="2"] {
        .font-size(1);
        padding: 0;
        sup {
          top: 0;
        }
      }
      .cart_detailTxt{font-size:11px;}
      sup{
        font-size:13px !important;
        /*Firefox*/
        @-moz-document url-prefix() {
          font-size:11px !important;
        }
        /*webkit*/
        @media screen and (-webkit-min-device-pixel-ratio:0){
          font-size:11px !important;
        }

      }
      
    }
    .kagoBody {
      margin: 0;
      >a {
        text-align: center;
        .font-size(1.4);
        font-weight: 600;
        display: block;
        line-height: 1.5em;
        padding: 10px 15px;
        background: @red;
        color: @white;
        text-decoration: none;
        .transition(opacity @transition-fast ease-in-out);
        &:before {
          .font-size(1.9);
          font-weight: 400;
          vertical-align: middle;
          font-family: 'hybstemplate';
          content: "\e600";
          margin-right: .3em;
        }
        &:hover {
          background: lighten(@red, 10%);
        }
      }
      
    }
  }
  //ブログバナー
  .element_blog_banner,
  .element_form_banner {
    a {
      text-decoration: none;
      .font-size(1.4);
      line-height: 1.3em;
      letter-spacing: .2em;
      display: block;
      background: @inverse;
      color: @white;
      text-align: center;
      padding:15px 10px;
      .transition(opacity @transition-fast ease-in-out);
      &:before {
        display: inline-block;
        .font-size(2.3);
        line-height: .6;
        font-family: 'hybstemplate';
        content: "\e603";
        margin-right: .3em;
        vertical-align: top;
      }
      &:hover {
        opacity: .8;
        -ms-filter: alpha(opacity=80);
        filter: alpha(opacity=80);
      }
    }
  }
  .element_form_banner a:before {
    line-height: 1;
    .font-size(1.7);
    content: "\e601";
  }
  //ごあいさつ
  .conceptelement {
    .title {
      border-top: 1px solid darken(@light-grey, 21.5%);
      h3, h4 {
        display: table-cell;
        .font-size(1.8);
        height: 57px;
        height: 5.7rem;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }
    .contents {
      >p {
        margin-top: 0;
        text-align: justify;
        line-height: 1.7;
      }
    }
  }
}

#main > .block,
#side > .block {
  //*****************************************
  //* メインカラムブロック共通部
  //*****************************************/
  .element {
    .extitle {
      display: table;
      width: 100%;
      border-top: 1px solid darken(@light-grey, 21.5%);
      .transition(background @transition-fast ease-in-out);
      &>.title {
        padding-left: 9px;
        padding-left: .9rem;
        h3 {
          display: table-cell;
          .font-size(1.8);
          height: 57px;
          height: 5.7rem;
          padding:10px 0;
          line-height: 1.5;
          vertical-align: middle;
        }
      }
      &>.listicon {
        width: 1%;
        display: table-cell;
        vertical-align: middle;
        a {
          color: @link-color-grey;
          height: 100%;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          &:after {
            text-decoration: none;
            display: inline-block;
            width: 26px;
            font-family: 'hybstemplate';
            content: "\e609";
          }
          img {
            //テンプレートデフォルトの<img>を非表示
            display: none;
          }
        }
      }
      &:hover {
        background: @light;
      }
    }
  }
  //*****************************************
  //* メインカラムブロックパターンごと
  //*****************************************/
  //画像横並びパターン
  .layout_b_pattern1, //分類
  .layout_b_pattern2, //分類
  .layout_b_pattern11, //新着
  .layout_b_pattern12 //新着
  {
    .contents {
      .clearfix;
    }
    .list_imgflo{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .contents .wrapLink{
      width: 25%;
      padding: 1.378%;
      @media @xsmall {
        width: 50%;
      }
    }
    .artloop {
      margin: 0 0 29px 0;
      &.list1 h4 {
        //イメージのみの場合
        margin-bottom: 0;
      }
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
      a.thum_img {
        display: block;
      }
      &.list2 a.thum_img {
        margin-bottom: 14px;
      }
      .catch {
        margin-top: 1em;
      }
    }
    .block_title +.contents{
      margin-bottom: 14px;
      margin-top: -14px;
    }
  }
  .layout_b_pattern1, //分類
  .layout_b_pattern11{ //新着
    .artloop{
      width: 25%;
      padding: 1.378%;
      @media @xsmall {
        width: 50%;
      }
    }
  }
  //2列パターン
  .layout_b_pattern3, //分類
  .layout_b_pattern8, //分類
  .layout_b_pattern13, //新着
  .layout_b_pattern17 //新着
  {
    .contents {
      .clearfix;
    }
    .artloop {
      width: 50%;
      float: left;
      padding: 9px;
      margin: 0 0 29px 0;
      margin-bottom: 29px !important; //テンプレートにmargin-bottom:10px;が記述されているため
      @media @xsmall {
        width: 100%;
      }
      img {
        .square(160px);
        &.small {
          .square(100px);
        }
      }
      >table{
        border: 0;
        margin: 0;
        >tbody>tr>th,
        >tbody>tr>td{
          border: 0;
        }
      }
    }
    .block_title +.contents{
      margin-top: -15px;
    }
  }
  //1列パターン
  .layout_b_pattern4, //分類
  .layout_b_pattern7, //分類
  .layout_b_pattern5, //分類
  .layout_b_pattern14, //新着
  .layout_b_pattern15 //新着
  {
    h4.catetitle, .block_title > h4 {
      margin-bottom: 0;
    }
    .artloop {
      margin-bottom: 0 !important; //テンプレートにmargin-bottom:10px;が記述されているため
      border-top: 1px solid @light-grey;
      padding:14px 9px;
      padding:14px .9rem;
      h5 {
        display: inline-block;
      }
      p {
        margin-top: 0;
      }
      h5 +p{
        margin-top: .7em;
      }
      h5 +p:not(.catch){
        margin-bottom: 0;
      }
      .catch {
        margin: .7em 0;
      }
      .thum_img img {
        .square(220px);
        &.small {
          .square(160px);
        }
      }
      .date {
        margin-top: 0;
      }
      >table{
        border: 0;
        margin: 0;
        >tbody>tr>th,
        >tbody>tr>td{
          border: 0;
        }
      }
    }
    >.contents a.wrapLink:first-child .artloop{
      border-top: none;
    }
  }
  //カテゴリのみパターン
  .layout_b_pattern6 {
    .extitle{
      display: none;
    }
    .list_cat {
      margin: 0 -6px;
      .clearfix;
      >.title {
        display: inline-block;
        max-width: 300px;
        padding: 0 6px;
        h4 {
          margin-bottom: 12px;
          width: 100%;
        }
        a {
          .truncate();
          width: 100%;
          overflow: hidden;
          border: none;
          position: relative;
          .font-size(1.4);
          vertical-align: middle;
          text-align: center;
          padding:6px 1.4em 6px 1em;
          line-height: 1.4;
          text-indent: 0;
          background: @inverse;
          color: @white;
          .transition(opacity @transition-fast ease-in-out);
          &:after {
            .font-size(1.2);
            position: absolute;
            right: .6em;
            top:50%;
            line-height: 1px;
            display: inline-block;
            font-family: 'hybstemplate';
            content: "\e609";
          }
          &:hover {
            opacity: .8;
            -ms-filter: alpha(opacity=80);
            filter: alpha(opacity=80);
          }
        }
      }
    }
  }
  //*****************************************
  //* メインカラムブロック:黄色ブロック
  //*****************************************/
  //コンタクト
  .contactelement {
    border: 1px solid darken(@light-grey, 10%);
    margin-bottom: 2em;
    padding: 36px 47px;
    @media @xsmall {
      padding: 20px 17px;
    }
    .title {
      h3 {
        .font-size(1.8);
      }
    }
    .telnumber {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1em 20px;
      dl{
        display: flex;
        align-items: center;
        margin: 0;
        width: 50%;
        padding: 0 1em;
        @media @xsmall {
          width: 100%;
        }
      }
      dt,dd{
        margin: 0;
      }
      dt {
        padding: 0;
        white-space: nowrap;
        span {
          .font-size(1.5);
          text-align: center;
          letter-spacing: .3em;
          text-indent: .3em;
          display: inline-block;
          line-height: 2em;
          width: 4.5em;
          margin-right: 1em;
          background: @inverse;
          color: @white;
          @media @xsmall {
            font-size: 1.2rem;
          }
        }
      }
      dd {
        .font-size(2.6);
        font-weight: normal;
        letter-spacing: .07em;
        white-space: nowrap;
        @media @xsmall {
          font-size: 1.4rem;
        }
      }
    }
    .company_name {
      .font-size(1.4);
      margin-bottom: 0;
      line-height: 2;
      padding-left: .5em;
    }
    .contact_detail {
      width: 100%;
      border-width: 0;
      margin-top: 0;
      tr {
        border-top: 1px solid @light-grey;
      }
      th,
      td {
        .font-size(1.2);
        border-width: 0 0 1px;
        line-height: 2;
        padding: 0;
        border-width: 0;
        background: none;
      }
      th {
        font-weight: normal;
        text-align: left;
        width: 7em;
        padding-left: .5em;
      }
      td {
        padding: 0;
        address {
          font-style: normal;
        }
      }
    }
    .btn_t {
      a {
        text-decoration: none;
        text-align: center;
        .font-size(1.4);
        display: block;
        background: @light-grey;
        line-height: 2.2em;
        letter-spacing: .5em;
        text-indent: .5em;
        .transition-property(~"background,color");
        .transition(@transition-fast ease-in-out);
        color: @link-color-grey;
        @media @xsmall {
          font-size: 1.2rem;
          letter-spacing: 0.1em;
          text-indent: 0.1em;
        }
        &:after {
          margin-left: 1em;
          font-family: 'hybstemplate';
          content: '\e609';
        }
        &:hover {
          background: @inverse;
          color: @white;
        }
      }
    }
  }
  .contactelement.is-sideblock{
    padding: 20px 17px;
    .title {
      h4 {
        margin-bottom: 20px;
        .font-size(1.8);
      }
    }
    .telnumber{
      dl{
        width: 100%;
      }
      dt{
        span{
          .font-size(1.2);
          width: 3em;
          text-align: center;
          letter-spacing: 0.1em;
          text-indent: 0.1em;
          line-height: 1.5;
        }
      }
      dd{
        text-align: left;
        .font-size(1.4);
        font-weight: normal;
        letter-spacing: .1em;
      }
    }
    .company_name{
      .font-size(1.2);
      padding-left: 0;
    }
    .contact_detail{
      th{
        padding-left: 0;
        width: auto;
        white-space: nowrap;
        padding-right: 0.5em;
      }
      address{
        margin: 0;
      }
    }
    .btn_t{
      a{
        font-size: 1.2rem;
        letter-spacing: 0.1em;
        text-indent: 0.1em;
      }
    }
  }
} //.block



#side > .block {
  //*****************************************
  //* サイドカラムブロック共通部
  //*****************************************/
  .element {
    > a.wrapLink + .title{
      border-top: 0;
    }
    >.title {
      width: 100%;
      display: table;
      // border-top: 1px solid darken(@light-grey, 21.5%);
      h4 {
        font-size: 1.6rem;
        line-height: 1.5;
        vertical-align: middle;
      }
      &:hover {
        .transition(background @transition-fast ease-in-out);
        background: @light;
      }
    }
    .catart .cat {
      list-style: none;
      margin: 0;
      li {
        margin-bottom: 16px;
      }
    }
    .list_imgflo{
      br.clear{
        display: none;
      }
    }
    .art,
    .contents_text,
    .contents_imgflo {
      margin: 0;
      >.contents,
      > .wrapLink >.contents{
        margin-bottom: 29px;
        .transition(background @transition-fast ease-in-out);
        h5 {
          .font-size(1.4);
          line-height: 1.4;
          margin-bottom: 0;
          font-weight: 600;
          &.titleOnly{
            font-weight: 400;
          }
          >a {
            color:@link-color-grey;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &:hover {
          background: @light;
        }
        p.catch {
          margin: .5em 0;
        }
        p.cart_list_price {
          .price {
            display: block;
          }
        }
      }
      table {
        tr td {
          padding: 0;
          vertical-align: top;
          &:first-child {
            width: 1%;
            padding-right: 9px;
          }
        }
      }
    }
    .art .contents{
      width: 100%;
      overflow: hidden;
    }
    .thum_img {
      display: block;
      img {
        display: block;
      }
    }
  }
  //*****************************************
  //* サイドカラムブロックパターンごと
  //*****************************************/
  //画像横並びパターン
  .layout_b_pattern1, //分類
  .layout_b_pattern11 //新着
  {
    .art {
      margin: 0 0 16px;
      .clearfix;
    }
    .artloop {
      //個別記事
      width: 33.33%;
      margin-bottom: 0;
      float: left;
      padding: 2px;
      a{
        img{
          box-shadow: @black 0 0 0;
          background-color: @white;
          .transition(opacity @transition-fast ease-in-out);
        }
        &:hover img{
          opacity: .8;
          -ms-filter: alpha(opacity=80);
          filter: alpha(opacity=80);
        }
      }
    }
  }
  //画像あり1列パターン
  .layout_b_pattern2, //分類
  .layout_b_pattern3, //分類
  .layout_b_pattern4 //分類
  {
    .catart .cat {
      li {
        margin-bottom: 0;
      }
    }
    .art {
      .contents,
      .wrapLink .contents{
        border-top: 1px solid @light-grey;
        padding: 8px 0;
        margin-bottom: 0;
      }
      .wrapLink:first-child .contents {
        border-top: none;
      }
      .contents{
        >table{
          border: 0;
          margin: 0;
          >tbody>tr>th,
          >tbody>tr>td{
            border: 0;
          }
        }
      }
    }
  }
  .layout_b_pattern12, //新着
  .layout_b_pattern13, //新着
  .layout_b_pattern14 //新着
  {
    .title_side_block +.contents {
      .contents {
        padding: 8px 0;
        margin-bottom: 0;
        border-top: 1px solid @light-grey;
        &:hover {
          .transition(background @transition-fast ease-in-out);
          background: @light;
        }
      }
      .wrapLink:first-child .contents{
        border-top: none;
      }
      .contents{
        >table{
          border: 0;
          margin: 0;
          >tbody>tr>th,
          >tbody>tr>td{
            border: 0;
          }
        }
      }
    }
    .date {
      margin: 0 0 .5em;
    }
  }
  // 2列パターン
  .layout_b_pattern3,
  .layout_b_pattern8,
  .layout_b_pattern13{
    .artloop{
      width: 100%;
      float: none;
      img{
        width: 80px;
        height: 80px;
        @media @xsmall {
          width: 160px;
          height: 160px;
        }
      }
    }
  }
  //画像なし1列パターン
  .layout_b_pattern5, //分類
  .layout_b_pattern15 //新着
  {
    .catart .cat {
      li {
        margin-bottom: 0;
      }
    }
    .block_title + .contents .artloop:first-child{
      border-top: 0;
    }
    .art,
    .contents.contents_text {
      .contents {
        padding: 8px 0;
        margin-bottom: 0;
        border-top: 1px solid @light-grey;
        p {
          margin-top: 0;
        }
        .date {
          margin-top: 0;
          margin-bottom: .5em;
        }
        h5.titleOnly {
          padding-left: 9px;
          padding-left: .9rem;
          a{
            text-decoration: none;
          }
        }
      }
      .wrapLink:first-child .contents{
        border-top: none;
      }
    }
  }
  //カテゴリのみパターン
  .layout_b_pattern6 //分類
  {
    .title_side_block{display: none;}
    .catart .cat li {
      margin-bottom: -1px;
    }
  }
  //*****************************************
  //* サイドカラムブロック:黄色ブロック
  //*****************************************/
  .element_blog_banner,
  .element_form_banner{
    margin-bottom: 1em;
  }
}