@import 'turret/turret';
//webfont 読み込み
@import 'fonts/Roboto';
@import 'fonts/NotoSans';
@import 'fonts/iconfont';
@import 'fonts/font-awesome';

html {
  &.font-small {
    font-size: 10px;
  }
  &.font-middle {
    font-size: 12px;
  }
  &.font-large {
    font-size: 14px;
  }
  @media @xsmall {
    &.is-drawer-active{
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

body {
  .font-size(1.2);
  transition: transform @transition-fast ease-in-out;
  @media @xsmall {
    .is-drawer-active &{
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
a{
  text-decoration: none;
  outline:none;
  &:hover{
    text-decoration: underline;
  }
  &:focus{
    outline:none;
  }
}

.status {
  width: 100%;
  text-align: center;
  .text-light;
  .background-info;
}

//LAYOUT
#allbox{
  overflow: hidden;
  @media @xsmall {
    padding-top: 60px;
    &:after{
      position: fixed;
      content: '';
      transition: opacity @transition-fast ease-in-out;
      opacity: 0;
    }
    .is-drawer-active &:after{
      top: 0; left: 0; bottom: 0;
      width: 100%; height: auto;
      background-color: fade( @black, 80% );
      opacity: 1;
    }
  }
}
header,
#container {
  margin: 0 auto;
  width: 960px;
  max-width: 100%;
  #side {
    float: right;
  }
  &.main-right {
    #main {
      float: right;
    }
    #side {
      float: left;
    }
  }
}
.mainImg{
  width: 100%;
  overflow: hidden;
  text-align: center;
  .mainImgInner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

#main {
  width: 725px;
  max-width: 100%;
  padding: 0;
  @media (max-width: 960px) {
    width: 100%;
  }
}

#side {
  width: 200px;
  max-width: 100%;
  padding: 0;
  @media @xsmall {
    width: 100%;
  }
}

.toolbar {
  max-width: 100%;
  margin: 10px auto 10px;
  h1 {
    .font-size(1.2);
    line-height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .tools {
    padding-left: 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    @media @small-down {
      display: none;
    }
    .button {
      border-radius: 0;
    }
  }
  &-row{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .column-1-2{
      float: none;
      width: 50%;
      @media @small-down {
        width: 100%;
      }
    }
  }
}

.hybsSearch {
  background: @white;
  margin: 0 15px 0 0;
  width: 198px;
  border: 1px solid #cecece;
  input[name="search"] {
    border: none;
    height: 26px;
    padding: 0 10px;
  }
  button[type="submit"].button {
    padding: 4px 5px;
    height: 26px;
  }
  .icon-search:before {
    font-size: 18px;
  }
}

.fontChange {
  font-size: 0;
  margin-right: 10px;
  display: flex;
  align-items: stretch;
  label {
    line-height: 28px;
    font-size: 12px;
    display: block;
    margin: 0 10px 0 0;
    white-space: nowrap;
  }
  button {
    height: 28px;
    width: 45px;
    vertical-align: top;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
    border: none;
    background: #efefef;
    &.font-middle {
      font-size: 14px;
    }
    &.font-large {
      font-size: 16px;
    }
    &.current {
      color: @button-inverse-color;
      background: @button-inverse-background;
      border: @button-inverse-border;
      &:hover, &:focus {
        color: @button-inverse-hover-color;
        background: @button-inverse-hover-background;
        border: @button-inverse-hover-border;
      }
    }
  }
}

.hybsLogin {
  background: @white;
  .login-button {
    display: block;
    .hide-text;
    .square(28px);
    padding: 0;
    border: 1px solid #cecece;
    .transition-property(~"background-color,border-color");
    background: url(../images/hybs_login.png) no-repeat 7px center;
    &.is--login{
      background: url(../images/hybs_login.png) no-repeat -22px center;
    }
    &:hover,
    &:active{
      background: url(../images/hybs_login.png) no-repeat -22px center;
      background-color: #c4c8d2;
    }
    &:focus{
      background: url(../images/hybs_login.png) no-repeat -22px center;
    }
  }
}

header {
  table tr {
    th, td {
      padding: 0;
    }
  }
  &#header{
    @media @xsmall {
      position: fixed;
      top: 0; left: 0;
      width: 100%;
      background-color: @white;
      z-index: 20;
      box-shadow: 0 0 5px fade( @black, 80% );
      height: 60px;
    }
  }
  &#header .site_title{
    text-decoration: none;
    border-bottom:1px solid transparent;
    line-height: 1.5;
    .transition-property(~"border-bottom-color,color");
    &:hover{
      color:lighten(@link-color,15%);
      border-bottom:1px solid lighten(@link-color,50%);
      text-decoration: none;
    }
  }
  &.is-default{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media @xsmall {
      justify-content: center;
    }
    img{
      max-width: 100%;
      height: auto;
      @media @xsmall {
        max-height: 40px;
      }
    }
    @media @xsmall {
      padding: 10px 4.5rem;
    }
    a{
      transition: opacity @transition-fast ease-in-out;
      &:hover,&:focus{
        opacity: 0.7;
      }
    }
    .drawerNav-btn{
      position: absolute;
      top: 50%; right: 7px;
      transform: translateY( -50% );
      border-width: 0;
      padding: 0.25em;
      font-size: 2.8rem;
      height: auto;
      z-index: 101;
      background-color: transparent;
      transition: all @transition-fast ease-in-out;
      @media @small-up {
        display: none;
      }
    }
  }
}

#gmenu.is-default {
  display: flex;
  justify-content: center;
  overflow: auto;
  &.is-open{
    @media @xsmall {
      transform: translateX( 0 );
    }
  }
  .nav-inline {
    &__list{
      width: 960px;
      max-width: 100%;
      margin: 30px auto 30px;
      display: flex;
      justify-content: center;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      border: 1px solid #aaa;
      border-width: 0 1px;
      padding: 0 10px;
      margin: 0;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 10px;
        line-height: 1.4em;
        text-align: center;
        vertical-align: middle;
        .font-size(1.5);
        white-space: nowrap;
        text-overflow: ellipsis;
        color: @link-color-grey;
        &:hover {
          color: #999;
        }
      }
      & + li{
        border-left: 0;
      }
    }
    &.overwidth li a{
      white-space: normal;
    }
  }
}
#gmenu.is-drawerNav--default{
  @media @xsmall {
    justify-content: flex-start;
    position: fixed;
    top: 0; left: 100%;
    width: ~"calc( 100vw - 54px )";
    height: 100%;
    transition: all @transition-fast ease-in-out;
    background-color: @white;
    box-shadow: 0 0 6px fade( @black, 20%);
    z-index: 100;
    background-color: @white;
    opacity: 0;
  }
  &.is-open{
    @media @xsmall {
      opacity: 1;
      transform: translateX( -100% );
    }
  }
  .gmenu__inner{
    @media @xsmall {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 60px 22px 22px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .gmenu__button-inquiry{
    position: relative;
    height: 5.5rem;
    font-size: 1.4rem;
    align-items: center;
    justify-content: center;
    &:after{
      position: absolute;
			content: "\f105";
			font-family: fontAwesome;
			width: 1.6em;
			top: calc( 50% - 0.6em ); right: 0;
			font-weight: @medium-font-weight;
    }
    @media @small-up {
      display: none;
    }
    @media @xsmall {
      display: flex;
    }
    &:hover,&:focus{
      text-decoration: none;
    }
  }
  .nav-inline{
    &__list{
      @media @xsmall {
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 0 2rem;
        padding: 0;
        width: 100%;
      }
    }
    &__item{
      @media @xsmall {
        position: relative;
        flex-grow: 0;
        border-width: 0;
        padding: 0;
        border: solid tint( @black, 90% );
        border-width: 1px 0;
        &.is-form{
          display: none;
        }
      }
      & + li{
        border-top-width: 0;
      }
    }
  }
  .nav-inline{
    &__sublist{
      margin: 1.5rem -0.6em;
      white-space: nowrap;
    }
    &__subitem{
      position: relative;
      display: inline-block;
      padding:  0.2em 0.6em;
      margin: 0;
      font-size: 1.2rem;
      &:before{
        position: absolute;
        top: 50%; left: 0;
        transform: translate( -50%, -50% );
        content: '';
        height: 1em; width: 1px;
        background-color: tint( @body-color, 30%);
        display: none;
      }
      & + li:before{
        display: block;
      }
      a{
        color: tint( @body-color, 30% );
      }
    }
  }
  .gmenu__copyright{
    color: tint( @body-color, 30% );
    font-size: 1.2rem;
  }
  .drawerNav-btn.is-close{
    position: absolute;
    top: 0; right: 0;
    border-width: 0;
    width: 60px; height: 60px;
    padding: 10px;
    z-index: 1;
    background-color: fade( @white, 50% );
    &:hover,&:focus{
      border-width: 0;
    }
    @media @small-up {
      display: none;
    }
    span{
      position: absolute;
      top: 50%; left: 50%;
      height: 2px; width: 20px;
      background-color: @body-color;
      &:nth-child(1){
        transform: translate( -50%, -50%) rotate( 45deg );
      }
      &:nth-child(2){
        transform: translate( -50%, -50%) rotate( -45deg );
      }
    }
  }

}

.mainImg {
  margin-bottom: 35px;
}

.socialButton {
  .detailfooter &,
  .element_mailform form + &{
    .clearfix;
  }
  ul {
    list-style: none;
    .detailfooter &,
    .element_mailform form + &{
      float: right;
      li{
        display: inline-block;
        margin-left: 12px;
      }
    }
  }
  li {
    margin: 0 0 7px 0;
  }
  a {
    display: inline-block;
    vertical-align: top;
    height: 28px;
    font-size: 0;
    color: #fff;
    border-radius: 2px;
    &:visited {
      color: #fff;
    }
    &:hover {
      color: #fff;
      opacity: .8;
    }
    i {
      display: inline-block;
      line-height: 28px;
      text-align: center;
      .square(28px);
      .font-size(1.3);
      border-radius: 2px;
    }
    span {
      .font-size(1.4);
      display: inline-block;
      line-height: 28px;
      padding: 0 5px;
      font-weight: 700;
      font-family: 'Roboto';
    }
    &.sns-fb {
      background-color: #788cb8;
      i {
        background-color: #3f5b98;
      }
    }
    &.sns-tw {
      background-color: #8cd4e9;
      i {
        background-color: #5cc2e1;
      }
    }
    &.sns-gp {
      background-color: #d27970;
      i {
        background-color: #c03f35;
      }
    }
    &.sns-hb {
      background-color: #63a1d1;
      i {
        .font-size(1.5);
        background-color: #2577bf;
      }
    }
  }
}

footer {
  text-align: center;
  a.pagetop {
    display: block;
    background: @inverse;
    padding-top: 2px;
    color: @white;
    padding-bottom: 10px;
    text-decoration: none;
    .font-size(1.4);
    .transition(opacity @transition-fast ease-in-out);
    &:visited {
      color: @white;
    }
    &:hover,
    &:focus {
      opacity: .8;
      color: @white;
      text-decoration: none;
    }
    &:before {
      text-align: center;
      display: block;
      font-family: 'hybstemplate';
      content: "\e602";
      font-size: 7px;
      line-height: 19px;
    }
  }
  #footerNav {
    background: darken(@light-grey, 4.5%);
    padding: 30px 1.2em 0;
    @media @xsmall {
      padding-top: 2em;
    }
    .contentsNav,
    .subNav{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @media @xsmall {
        flex-direction: column;
      }
      li{
        margin: 0;
        @media @xsmall {
          width: 100%;
          text-align: left;
        }
      }
      li a{
        display: block;
        padding: 5px 10px;
        white-space: nowrap;
        line-height: 1.5;
        .font-size(1.4);
        color: @link-color-grey;
        @media @xsmall {
          white-space: normal;
        }
        &:hover {
          color: @grey;
        }
      }
      &.overwidth li a{
        white-space: normal;
      }
    }
    .contentsNav {
      width: 960px;
      max-width: 100%;
      margin: 0 auto 30px;
      @media @small-up {
        border-left: 1px solid #aaa;
      }
      li {
        border-right: 1px solid #aaa;
        @media @xsmall {
          border-right-width: 0;
        }
        a{
          text-decoration: none;
        }
      }
    }
    .subNav {
      padding-bottom: 30px;
      margin-bottom: 20px;
      li {
        margin: 0 10px;
        @media @xsmall {
          margin: 0;
        }
        a {
          text-decoration: none;
          .font-size(1.2);
          padding: 0 10px;
          @media @xsmall {
            padding: 0.5em;
          }
          &:before {
            font-family: 'hybstemplate';
            content: "\e609";
            margin-right: 10px;
          }
        }
      }
    }
  }
  .copyright {
    display: block;
    margin-bottom: 30px;
    a {
      text-decoration: none;
      letter-spacing: .1em;
      color: lighten(@body-color,20%);
      .font-size(1);
      cursor: default;
      &:visited {
        color: lighten(@body-color,20%);
      }
    }
  }
  .promotion{
    display: block;
    margin-bottom: 30px;
    a{
      color: lighten(@body-color,20%);
      text-decoration: none;
      letter-spacing: .1em;
      .font-size(1);
      cursor: default;
      &:visited {
        color: lighten(@body-color,20%);
      }
    }
  }
  .pv_switch {
    margin:0;
    a {
      font-size:3.5rem;
      text-align: center;
      display: block;
      line-height: 3.5em;
      text-decoration: none;
      background: #4b4b4b;
      color:@white;
      font-weight: bold;
      &:before{
        font-size:3.6rem;
        font-weight: normal;
        margin-right: .3em;
      }
      &:active,&:hover{
        opacity:.8;
      }
    }
  }

}

br.clear {
  clear: both;
  content: " ";
  display: table;
  *zoom: 1;
}

//*****************************************
//* 共通部(ブロック・一覧・詳細)
//*****************************************/
#main > .block .element_block,
#side > .block .element_block,
#main > .element_list{
  margin-bottom: 28px;
  @media @xsmall {
    padding-left: 20px;
    padding-right: 20px;
  }
  &:empty{
    margin-bottom: 0;
  }
  //カテゴリ表記
  h4.catetitle,
  .block_title > h4,
  .catart .cat > li,
  .list_title1 h4{
    margin: 0 0 29px 0;
    a {
      text-decoration: none;
      display: block;
      padding:5px 9px;
      line-height: 1.4;
      border: 1px solid darken(@light-grey, 4.5%);
      border-width: 1px 0;
      background-color: @light;
      color: @link-color-grey;
      .transition(@transition-fast ease-in-out);
      .transition-property(~"background,color");
      &:hover {
        background-color: lighten(@grey, 4.5%);
        color: @white;
      }
    }
  }
  p {
    //文字組
    .font-size(1.2);
    &.catch,
    &.catchcp,
    &.catch strong {
      font-weight: 400;
      .font-size(1.3);
      color: @body-color;
      word-break: break-all;
    }
    &.catch + p{ //本文
      margin-bottom: 0;
      word-break: break-all;
    }
    &.date:empty {
      margin-bottom: 0;
    }
    &.cart_list_price {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 400;
      font-family: 'Roboto';
      .price2 {   //定価
        margin-top: .8em;
        display: block;
        color: @body-color;
        text-decoration: line-through;
      }
      .price.price3,
      .price3{    //お問い合わせ下さい
        .font-size(1.2);
      }
      .price {    //販売価格
        .font-size(2);
      }
      .taxtxt2{
        vertical-align: text-bottom;
      }
    }
  }
  h5 {
    //newマーク
    >.new, & +.new {
      .hide-text;
      &:after {
        display: inline-block;
        background: @red;
        color: @white;
        .font-size(.86);
        .size(4.55em, 2em);
        line-height: 2em;
        font-family: 'hybstemplate';
        content: "\e60a";
        text-align: center;
        vertical-align: top;
        margin: 0 -9px 0 2px;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    .catetitle {
      display: inline-block;
      margin: 0 0 0 3px;
      .font-size(1);
      color: @body-color;
      a {
        color: @body-color;
      }
    }
  }
  .wrapLink{  //記事ブロックを外包するリンク
    display: block;
    text-decoration: none;
    .transition(background @transition-fast ease-in-out);
    &:hover {
      background: @light;
      h5 > a{
        text-decoration: underline;
      }
    }
  }
  .element_freearea,
  .element .entry{
    width: 100%;
    overflow: hidden;
    color: @body-color;
  }
  .art{
    h5 > a{
      color: @link-color-grey;
    }
  }
  .artloop {  //artloopはメインカラムにしか無い
    .transition(background @transition-fast ease-in-out);
    width: 100%;
    overflow: hidden;
    h5 {
      .font-size(1.4);
      line-height: 1.4;
      margin-bottom: 0;
      font-weight: 600;
      &.titleOnly{
        font-weight: 400;
      }
      >a{
        color: @link-color-grey;
      }
      .catetitle {
        display: inline-block;
        margin-left: 3px;
        .font-size(1);
        color: @grey;
        a {
          color: @grey;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      & + .entry{
        margin-top: 1em;
      }
    }
    &:hover {
      background: @light;
    }
    >table >tbody >tr >td {
      padding: 0;
      vertical-align: top;
      &:first-child {
        width: 1%;
        padding-right: 20px;
      }
    }
  }
  .list_imgflo .cover-thumb{
    display: inline-block;
    width: 200px;
    height: 200px;
    max-width: 100%;
    @media @xsmall {
      width: 30vw;
      height: 30vw;
    }
  }
  &.layout_b_pattern1 .cover-thumb,
  &.layout_b_pattern11 .cover-thumb,
  &.layout_b_pattern2 .cover-thumb,
  &.layout_b_pattern12 .cover-thumb{
    width: 100%;
    height: 162px;
  }
  .img-list1 .cover-thumb,
  .img-list2 .cover-thumb{
    width: 100%;
    height: 162px;
  }
}
#main > .element_detail{
  margin-bottom: 28px;
}
//lightbox
#lightbox-container-image-data-box{
  .box-sizing(content-box);
}
// ポップアップスタイル
button.mfp-close,
button.mfp-arrow{
  &:hover,
  &:focus{
    border: none;
    background-color: transparent;
    color: #FFF;
  }
}



@import './_breadcrumbs';
@import './_blocks';
@import './_contents';
//110_v1用のカスタムスタイル
@import './_110_v1';