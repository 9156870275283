/*
 * Definitions
 */

// Input
@input-height: @height-medium;
@input-margin: @label-margin;
@input-padding: 0 1.6rem;
@input-color: @body-color;
@input-font-family: @regular-font-family;
@input-font-weight: @regular-font-weight;
@input-font-size: @font-size-medium;
@input-line-height: @line-height-medium;
@input-background: tint( @black, 96% );
@input-border: 1px solid tint( @black, 85% );
@input-border-radius: @border-radius;

// Input Hover
@input-hover-color: @input-color;
@input-hover-background: darken(@input-background, 2%);
@input-hover-border: 1px solid darken(@light-grey, 10%);

// Input Focus
@input-focus-color: @input-color;
@input-focus-background: darken(@input-background, 2%);
@input-focus-border: 1px solid @info;

// Input Placeholder
@input-placeholder-font-family: @input-font-family;
@input-placeholder-font-size: @input-font-size;
@input-placeholder-color: fade(@input-color, 50%);
@input-placeholder-line-height: @input-line-height;
@input-placeholder-letter-spacing: 0px;
@input-placeholder-text-transform: none;

// Input Inverse
@input-inverse-color: @white;
@input-inverse-background: @inverse;
@input-inverse-border: none;

// Input Inverse Hover
@input-inverse-hover-color: @input-inverse-color;
@input-inverse-hover-background: darken(@input-inverse-background, 2.5%);
@input-inverse-hover-border: @input-inverse-border;

// Input Inverse Focus
@input-inverse-focus-color: @input-inverse-color;
@input-inverse-focus-background: darken(@input-inverse-background, 5%);
@input-inverse-focus-border: @input-focus-border;

// Input Disabled
@input-disabled-color: @light;
@input-disabled-background: @light;
@input-disabled-border: 1px solid darken(@light, 10%);

// Input Search
@input-search-border-radius: 2rem;

// Textarea
@textarea-padding: 1.6rem;
@textarea-line-height: @line-height-medium;


/*
 * Input
 */

input,
textarea {
	.input();

	.appearance(none);

	// Override Normalise for Input Types
	.box-sizing(border-box);

	// Input Size
	.input-size(@input-padding, @input-height, @input-font-size);
	
	// Input Style
	.input-style(@input-color, @input-background, @input-border);

	// Input Placeholder
	.placeholder(@input-placeholder-color, @input-placeholder-font-size, @input-placeholder-font-family, @input-placeholder-letter-spacing, @input-placeholder-text-transform);

	&:hover {
		.input-style(@input-hover-color, @input-hover-background, @input-hover-border);
	}

	&:focus {
		.input-style(@input-focus-color, @input-focus-background, @input-focus-border);
	}

	// Input Disabled
	&:disabled {
		cursor: no-drop;
		.input-style(@input-disabled-color, @input-disabled-background, @input-disabled-border);
	}
}


/*
 * Textarea
 */

textarea {
	max-width: 100%;
	.transition(~"color @{transition-fast} ease-in-out, background @{transition-fast} ease-in-out, border @{transition-fast} ease-in-out");
}
	
/*
 * Input Styles
 */

// Input Inverse
.input-inverse {

	// Input Inverse Style
	.input-style(@input-inverse-color, @input-inverse-background, @input-inverse-border);

	// Input Inverse Placeholder
	.placeholder(@input-inverse-color, @input-placeholder-font-size, @input-placeholder-font-family, @input-placeholder-letter-spacing, @input-placeholder-text-transform);

	&:hover {
		.input-style(@input-inverse-hover-color, @input-inverse-hover-background, @input-inverse-hover-border);
	}

	&:focus {
		.input-style(@input-inverse-focus-color, @input-inverse-focus-background, @input-inverse-focus-border);
	}
}
	
/*
 * Input Search
 */

// Input Search
input[type="search"] {
	border-radius: @input-search-border-radius;
	-webkit-appearance: none; 

	// Override Normalise for Input Types
	.box-sizing(border-box);
}

	
/*
 * Input File
 */

// Input File
input[type="file"] {
	padding: 1rem;
	height: auto;
	border: none;
	font-size: 1.4rem;
	cursor: pointer;

	// Override Normalise for Input Types
	.box-sizing(border-box);
}



/*
 * Checkbox and Radio
 */

// Checkbox and Radio Buttons
input[type="checkbox"],
input[type="radio"] {
	display: inline-block;
	margin-right: 10px;
	width: auto;
	height: auto;

	& + p {
		margin: 0;
		display: inline-block;
	}

	& + label {
		display: inline-block;
	}
}

input[type="checkbox"] {
	.appearance(checkbox);
}

input[type="radio"] {
	.appearance(radio);
}


/*
 * Textarea
 */

// Reset height for `textarea`s
textarea {
	padding: @textarea-padding;
	height: auto;
	line-height: @textarea-line-height;
}